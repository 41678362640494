import { TagsList } from 'lib/data/video/types';

import { BreadcrumbsProps } from '@/components/Breadcrumbs';
import { CoverBoxProps } from '@/components/CoverBox';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import React, { ReactNode } from 'react';
import { VideoBaseContent } from './VideoBaseContent';

export interface StandaloneVideoBaseContentProps extends StandaloneComponentProps<typeof VideoBaseContent> {
  children?: React.ReactNode;
  tagsList?: TagsList['tagsList'];
  breadcrumbs?: BreadcrumbsProps['paths'];
  label?: ReactNode;
  headline?: CoverBoxProps['headline'];
  description?: CoverBoxProps['markup'];
}

export const StandaloneVideoBaseContent: StandaloneComponent<StandaloneVideoBaseContentProps> = ({
  tagsList,
  headline,
  label,
  description,
  breadcrumbs,
  children,
  ...props
}) => {
  return (
    <VideoBaseContent {...props}>
      {tagsList && (
        <VideoBaseContent.TrendingMenu headline={label} tags={tagsList} options={props?.options?.$trendingMenu} />
      )}
      {breadcrumbs && <VideoBaseContent.Breadcrumbs paths={breadcrumbs} options={props?.options?.$breadcrumbs} />}
      {(headline || description) && (
        <VideoBaseContent.CoverBox headline={headline} markup={description || ''} options={props?.options?.$coverBox} />
      )}
      {children}
    </VideoBaseContent>
  );
};

export default StandaloneVideoBaseContent;
