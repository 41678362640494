import { merge } from '@/utils/merge';
import { tw } from '@/utils/tw';
import { default as BaseCoverBox } from 'base/components/CoverBox/theme';

const Prose = tw.theme({
  extend: BaseCoverBox.Prose,
  base: 'prose-coverbox',
  variants: merge(BaseCoverBox.Prose.variants, {
    size: { video: 'text-body-sm md:text-body-md' },
  }),
});

const CoverBox = tw.theme({
  extend: BaseCoverBox,
  slots: {
    base: 'bg-cherry-blossom-200 wings-cherry-blossom-200 py-3 md:py-6 md:data-[icon]:grid-cols-[auto_1fr]',
    headline: 'md:text-headline-2xl text-headline',
    icon: 'md:row-span-2',
    toggle: 'bg-cherry-blossom-200 text-headline-2xs sm:text-headline-2xs underline',
  },
  variants: {
    colors: {
      feelgood: {
        base: 'bg-clementine-200 wings-clementine-200',
        toggle: 'bg-clementine-200',
      },
      halsa: {
        base: 'bg-cherry-blossom-200 wings-cherry-blossom-200',
        toggle: 'bg-cherry-blossom-200',
      },
      medicin: {
        base: 'bg-eastern-blue-200 wings-eastern-blue-200',
        toggle: 'bg-eastern-blue-200',
      },
      'mode-och-skonhet': {
        base: 'bg-cannon-pink-200 wings-cannon-pink-200',
        toggle: 'bg-cannon-pink-200',
      },
      'nyttig-mat': {
        base: 'bg-emerald-200 wings-emerald-200',
        toggle: 'bg-emerald-200',
      },
      psykologi: {
        base: 'bg-elm-500 wings-elm-500',
        toggle: 'bg-elm-500',
      },
      relationer: {
        base: 'bg-orpiment-200 wings-orpiment-200',
        toggle: 'bg-orpiment-200',
      },
      reportage: {
        base: 'bg-cherry-blossom-200 wings-cherry-blossom-200',
        toggle: 'bg-cherry-blossom-200',
      },
      resor: {
        base: 'bg-cherry-blossom-200 wings-cherry-blossom-200',
        toggle: 'bg-cherry-blossom-200',
      },
      'senaste-nytt': {
        base: 'bg-cinnabar-200 wings-cinnabar-200',
        toggle: 'bg-cinnabar-200',
      },
      traning: {
        base: 'bg-yellow-sea-300 wings-yellow-sea-300',
        toggle: 'bg-yellow-sea-300',
      },
      vikt: {
        base: 'bg-cherry-blossom-200 wings-cherry-blossom-200',
        toggle: 'bg-cherry-blossom-200',
      },
    },
  },
});

export default Object.assign(CoverBox, { Prose });
