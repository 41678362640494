import { Content } from '@/types/content';

export function findFirstOfType(contentChildren: Content[], type: string): Content | undefined {
  for (const child of contentChildren) {
    if (child.type === type) {
      return child;
    }

    if (child.children.length) {
      const match = findFirstOfType(child.children, type);
      if (match) return match;
    }
  }

  return undefined;
}

export function findAllOfType(contentChildren: Content[], type: string): Content[] {
  return contentChildren.reduce((result, child) => {
    if (child.type === type) {
      result.push(child);
    }

    if (child.children?.length) {
      return [...result, ...findAllOfType(child.children, type)];
    }

    return result;
  }, [] as Content[]);
}
