import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { getImageServer } from '@/utils/getImageServer';
import { Content } from 'lib/labrador/types';
import Head from 'next/head';
import { appPageMeta } from './AppPageMeta';
import { shouldPageBeHiddenFromIndexing } from './utils';

export interface StandalonePageMetaProps extends StandaloneComponentProps {
  pageData: Content;
}

export const StandalonePageMeta: StandaloneComponent<StandalonePageMetaProps> = ({ pageData }) => {
  const suffix = pageData.data.titleSuffix || '';
  const title = `${pageData.data.seoTitle || pageData.data.title || ''}${suffix}`;
  const description = (pageData.data.seoDescription || pageData.data.subtitle || '') as string;
  const canonical = (pageData.data.canonical || '') as string;
  const ogType = pageData.type === 'article' ? 'article' : 'website';
  const ogTitle = (pageData.data.ogTitle || title || '') as string;
  const ogDescription = (pageData.data.ogDescription || description) as string;
  const ogImageUrl = (pageData.data.ogImageUrl || '') as string;
  const publishedDatetime = (pageData.data?.publishedDatetime || '') as string;
  const modifiedDatetime = (pageData.data?.modifiedDatetime || '') as string;
  const articleCategory = (pageData.data?.category || '') as string;
  const sanitizedDescription = description?.replace(/\<.+?\>/gi, '');
  const sanitizedOgDescription = ogDescription?.replace(/\<.+?\>/gi, '');

  const seoDisableIndexing = shouldPageBeHiddenFromIndexing(pageData);

  return (
    <Head>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <title>{title}</title>
      <meta name="title" content={title} key="metaTitle" />
      <meta name="description" content={sanitizedDescription} key="metaDescription" />
      <link rel="canonical" href={canonical}></link>
      <meta name="keywords" content={pageData.data.keywords as string} />
      <meta property="og:type" content={ogType} key="ogType" />
      <meta property="og:url" content={canonical} key="ogUrl" />
      <meta property="og:title" content={ogTitle} key="ogTitle" />
      <meta property="og:description" content={sanitizedOgDescription} key="ogDescription" />
      <meta name="page-modified" content={modifiedDatetime} />
      {ogType === 'article' && (
        <>
          <meta property="article:published_time" content={publishedDatetime} />
          <meta property="article:section" content={articleCategory} />
        </>
      )}
      {ogImageUrl && (
        <>
          <meta property="og:image" content={ogImageUrl} key="ogImage" />
          <meta property="og:image:width" content="1200" key="ogImageWidth" />
          <meta property="og:image:height" content="667" key="ogImageWidth" />
        </>
      )}
      <meta name="twitter:card" content="summary_large_image" key="twitterCard" />
      <meta name="twitter:title" content={ogTitle} key="twitterTitle" />
      <meta name="twitter:description" content={ogDescription} key="twitterDescription" />
      {ogImageUrl && (
        <>
          <meta name="twitter:image" content={ogImageUrl} key="twitterImage" />
        </>
      )}
      <meta name="robots" content="max-image-preview:large" />
      {seoDisableIndexing && <meta name="robots" content="max-image-preview:large,noindex" />}
      <link rel="preconnect" href={getImageServer()} />
      <link rel="preconnect" href="https://securepubads.g.doubleclick.net" />
      <link rel="dns-prefetch" href="https://securepubads.g.doubleclick.net" />

      {appPageMeta}
    </Head>
  );
};
