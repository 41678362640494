import { CoverBoxTheme } from '@/components/CoverBox/theme';
import { Prose as StandaloneProse } from '@/components/Prose';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: CoverBoxTheme });

const Base = $({ as: 'section' });
const Collapse = $({ as: 'div', slot: 'collapse' });
const Group = $({ as: 'div', slot: 'group' });
const Headline = $({ as: 'h1', slot: 'headline' });
const Icon = $({ as: 'div', slot: 'icon' });
const Images = $({ as: 'div', slot: 'images' });
const Prose = $({ as: StandaloneProse, theme: CoverBoxTheme.Prose });
const Toggle = $({ as: 'label', slot: 'toggle' });

export const CoverBox = Object.assign(Base, { Collapse, Group, Headline, Icon, Images, Prose, Toggle });
