import { Link, LinkProps } from '@/components/Link';
import { StandaloneComponent } from '@/types/component';

export interface VideoTeaserLinkProps extends LinkProps {
  mediaId?: string;
}

export const VideoTeaserLinkComponent: StandaloneComponent<VideoTeaserLinkProps> = ({
  href,
  mediaId,
  options,
  ...props
}) => {
  return <Link href={href} options={options} data-video-id={mediaId} {...props} />;
};
