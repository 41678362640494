import { BreadcrumbsTheme } from '@/components/Breadcrumbs/theme';
import { CoverBoxTheme } from '@/components/CoverBox/theme';
import { TrendingMenuTheme } from '@/components/TrendingMenu/theme';
import { tw } from '@/utils/tw';

const CoverBox = tw.theme({
  extend: CoverBoxTheme,
  base: 'text-body-sm',
  slots: {
    headline: 'whitespace-normal normal-case',
  },
});

const Breadcrumbs = tw.theme({
  extend: BreadcrumbsTheme,
});

const TrendingMenu = tw.theme({
  extend: TrendingMenuTheme,
  base: 'mx-auto w-full gap-3 px-0',
});

const VideoBaseContent = tw.theme({
  base: 'mb-10 flex flex-col gap-9 px-4 md:mb-16 md:gap-16 [&>*:first-child]:-mb-3',
});

export default Object.assign(VideoBaseContent, { CoverBox, TrendingMenu, Breadcrumbs });
