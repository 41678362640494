import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { useEffect, useId, useRef, useState } from 'react';
import { CoverBox } from './CoverBox';

export interface StandaloneCoverBoxProps extends StandaloneComponentProps<typeof CoverBox> {
  headline: React.ReactNode;
  markup: string;
  expand?: React.ReactNode;
  icon?: React.ReactNode;
  images?: JSX.Element[];
}

export const StandaloneCoverBox: StandaloneComponent<StandaloneCoverBoxProps> = ({
  headline,
  markup,
  expand,
  icon,
  images,
  ...props
}) => {
  const inputId = useId();
  const collapseRef = useRef<HTMLDivElement>(null);
  const [isCollapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (collapseRef.current) {
        setCollapsed(collapseRef.current.scrollHeight > collapseRef.current.clientHeight);
      }
    });

    if (collapseRef.current) {
      observer.observe(collapseRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <CoverBox {...props} data-icon={icon ? '' : undefined} data-collapsed={isCollapsed ? '' : undefined}>
      {icon && <CoverBox.Icon>{icon}</CoverBox.Icon>}

      <CoverBox.Headline>{headline}</CoverBox.Headline>

      <CoverBox.Group>
        <input id={inputId} type="checkbox" defaultChecked className="peer invisible hidden" />
        <CoverBox.Collapse ref={collapseRef}>
          <CoverBox.Prose markup={markup} />
          {Boolean(images?.length) && <CoverBox.Images>{images}</CoverBox.Images>}
          <CoverBox.Toggle htmlFor={inputId}>{expand ?? '... Läs mer'}</CoverBox.Toggle>
        </CoverBox.Collapse>
      </CoverBox.Group>
    </CoverBox>
  );
};
