export const stripHtml = (s: string | undefined): string => (s ? s.replace(/<\/?[^>]+(>|$)/g, '') : '');

export const stripMultiLineHtml = (s: string | undefined): string =>
  s
    ? s
        .replace(/<\/?[^>]+(>|$)/g, '//')
        .split('//')
        .filter(Boolean)
        .join(' ')
    : '';
