import { useClassName } from '@/styles/JwVideoTeaser';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';

export interface VideoTeaserMediaPlayIconProps extends ComponentProps<'span'> {}

export const VideoTeaserMediaPlayIconComponent: Component<VideoTeaserMediaPlayIconProps> = ({ children, ...props }) => {
  const className = useClassName('media_playIcon', props);

  return <span {...mergeProps({ className }, props)}>{children}</span>;
};
