import { useClassName } from '@/styles/JwVideoTeaser';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';
import { withNonHTMLChildren } from '@/utils/withNonHTMLChildren';
import { withSafeInnerHTML } from '@/utils/withSafeInnerHTML';

export interface VideoTeaserContentTitleProps extends ComponentProps<'h2'> {
  as?: Extract<keyof JSX.IntrinsicElements, 'h1' | 'h2' | 'h3' | 'h4' | 'h5'>;
}

export const VideoTeaserContentTitleComponent: Component<VideoTeaserContentTitleProps> = ({
  children,
  as,
  ...props
}) => {
  const className = useClassName('content_title', props, { defaults: { colors: 'primary' } });

  const CustomSelector = as || 'h2';

  return (
    <CustomSelector {...mergeProps({ className }, props)} {...withSafeInnerHTML(children)}>
      {withNonHTMLChildren(children)}
    </CustomSelector>
  );
};
