import Script from 'next/script';

export const getScriptElements = (contentIndex?: string | number, scriptsMarkup?: string[]) => {
  if (!scriptsMarkup) return null;

  const transformedScriptTags = scriptsMarkup?.map((scriptTag, index) => {
    const regex = /<script\s*([^>]*?)(?:\/>|>([\s\S]*?)<\/script>)/;
    const [, scriptProps = '', scriptContent = ''] = scriptTag.match(regex) ?? [];

    const props = scriptProps
      .trim()
      .split(/\s+/)
      .reduce((props: Record<string, any>, prop) => {
        const [key, value] = prop.split('=');
        if (key) {
          props[key] = value ? value.replace(/(^"|"$)/g, '') : true;
        }
        return props;
      }, {});

    return (
      <Script
        id={`${contentIndex}-${index}`}
        key={`${contentIndex}-${index}`}
        dangerouslySetInnerHTML={{ __html: scriptContent }}
        {...props}
      />
    );
  });

  return transformedScriptTags;
};
