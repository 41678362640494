import { tw } from '@/utils/tw';

const Breadcrumbs = tw.theme({
  slots: {
    base: `-my-3 flex items-center gap-2 md:-mb-9 md:-mt-6`,
    step: `text-body-xs whitespace-nowrap capitalize text-gray-600`,
    separator: `text-gray-600`,
  },
});

export default Breadcrumbs;
