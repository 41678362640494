import { BreadcrumbsTheme } from '@/components/Breadcrumbs/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: BreadcrumbsTheme });

const Base = $({ as: 'div', slot: 'base' });
const Step = $({ as: 'div', slot: 'step' });
const Separator = $({ as: 'span', slot: 'separator' });

export const Breadcrumbs = Object.assign(Base, { Step, Separator });
