import { useClassName } from '@/styles/JwVideoTeaser';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';

export interface VideoTeaserContentProps extends ComponentProps<'div'> {}

export const VideoTeaserContentComponent: Component<VideoTeaserContentProps> = ({ children, ...props }) => {
  const className = useClassName('content', props);

  return <div {...mergeProps({ className }, props)}>{children}</div>;
};
