import getConfig from 'modules/config';
import React from 'react';

const alWebShouldFallback = getConfig('app.al:web:should_fallback') || 'true';
const alAndroidUrl = getConfig('app.al:android:url');
const alAndroidAppName = getConfig('app.al:android:app_name');
const alAndroidPackage = getConfig('app.al:android:package');
const alIosUrl = getConfig('app.al:ios:url');
const alIosAppStoreId = getConfig('app.al:ios:app_store_id');
const alIosAppName = getConfig('app.al:ios:app_name');

const appFacebookDeepLinkingConfigAvailable =
  !!alWebShouldFallback &&
  !!alAndroidUrl &&
  !!alAndroidAppName &&
  !!alAndroidPackage &&
  !!alIosUrl &&
  !!alIosAppStoreId &&
  !!alIosAppName;

// Documentation 👉 https://developers.facebook.com/docs/applinks/metadata-reference/
const appFacebookDeepLinkingMetaData = appFacebookDeepLinkingConfigAvailable ? (
  <React.Fragment key="appFacebookDeepLinks">
    <meta property="al:web:should_fallback" content={alWebShouldFallback} />
    <meta property="al:android:url" content={alAndroidUrl} />
    <meta property="al:android:app_name" content={alAndroidAppName} />
    <meta property="al:android:package" content={alAndroidPackage} />
    <meta property="al:ios:url" content={alIosUrl} />
    <meta property="al:ios:app_store_id" content={alIosAppStoreId} />
    <meta property="al:ios:app_name" content={alIosAppName} />
  </React.Fragment>
) : undefined;

export const appPageMeta = [appFacebookDeepLinkingMetaData];
