import { Breadcrumbs as BreadcrumbsStandalone } from '@/components/Breadcrumbs';
import { CoverBox as CoverBoxStandalone } from '@/components/CoverBox';
import { TrendingMenu as TrendingMenuStandalone } from '@/components/TrendingMenu';
import { VideoBaseContentTheme } from '@/components/VideoBaseContent/theme';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: VideoBaseContentTheme });

const Base = $({ as: 'div' });
const CoverBox = GenericSlot({ as: CoverBoxStandalone, theme: VideoBaseContentTheme.CoverBox });
const TrendingMenu = GenericSlot({ as: TrendingMenuStandalone, theme: VideoBaseContentTheme.TrendingMenu });
const Breadcrumbs = GenericSlot({ as: BreadcrumbsStandalone, theme: VideoBaseContentTheme.Breadcrumbs });

export const VideoBaseContent = Object.assign(Base, { CoverBox, TrendingMenu, Breadcrumbs });
