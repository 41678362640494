import { tw } from '@/utils/tw';
import { default as BaseTagTheme } from 'base/components/Tag/theme';

const Tag = tw.theme({
  extend: BaseTagTheme,
  base: 'text-preview-sm',
  variants: {
    colors: {
      default: `border-cherry-blossom-100 bg-cherry-blossom-100 from-cherry-blossom-300 to-cherry-blossom-100 text-cherry-blossom-500 hover:border-cherry-blossom-300 hover:bg-cherry-blossom-300 hover:text-black focus:border-cannon-pink-400 focus:bg-gradient-to-b focus:text-black active:border-cannon-pink-400 active:bg-gradient-to-b active:text-black`,
      text: 'text-gray-600',
      alternative: 'text-gray-600',
    },
    size: {
      small: `px-3 pb-1 pt-0.5`,
      medium: `px-3 pb-2.25 pt-1.75`,
      large: `px-6 pb-4 pt-3.5`,
      text: `leading-snug`,
    },
    variant: {
      default: 'border-3',
      text: 'capitalize',
    },
  },
});

export default Tag;
