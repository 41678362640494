/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandalonePageMeta as ResolvedStandalonePageMeta } from 'base/components/standalone/PageMeta';

export const PageMeta: typeof ResolvedStandalonePageMeta = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandalonePageMeta {...props} />
        </ErrorBoundary>
    );
});

export type PageMetaProps = PropsFromComponent<typeof PageMeta>;

/** @deprecated Use slot architecture instead */
export const PageMetaWith = (extras: DynamicStandaloneExtras): typeof PageMeta => {
    return function PageMeta(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'PageMeta');
        return <ResolvedStandalonePageMeta {...mergeProps({ options: { theme } }, props)} />;
    }
}