import { OptionalLink } from '@/components/OptionalLink';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { StandaloneOptionalLinkProps } from '../OptionalLink';
import { Tag } from './Tag';

export type StandaloneTagProps = StandaloneComponentProps<typeof Tag> & {
  value?: ReactNode;
  link?: StandaloneOptionalLinkProps;
};

export const StandaloneTag: StandaloneComponent<StandaloneTagProps> = ({ value, link, ...props }) => {
  return <OptionalLink content={<Tag {...props}>{value}</Tag>} {...link} />;
};
