import { Content } from '@/types/content';
import { mergeDefined } from '@/utils/mergeDefined';
import { WebPage, WithContext } from 'schema-dts';
import {
  CustomSchemaMarkupConfig,
  getPublishedUrl,
  publisher,
  publishingPrinciples,
  sameAs,
  unnamedSourcesPolicy,
} from './utils';

// for front pages like /om-oss, /om-cookies, /alla-amnen .etc
// configured in 'schemaMarkup.webPages' in config.json
export const getWebPageSchema = (
  pageData: Content,
  schema?: Partial<WebPage & CustomSchemaMarkupConfig>,
): WithContext<WebPage & CustomSchemaMarkupConfig> => {
  const url = getPublishedUrl(pageData.data.publishedUrl);

  const webPageSchema: Partial<WithContext<WebPage & CustomSchemaMarkupConfig>> = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    url,
    name: pageData.data.seoTitle || pageData.data.title || '',
    description: pageData.data.seoDescription || pageData.data.subtitle || '',
    unnamedSourcesPolicy,
    mainEntityOfPage: {
      '@type': 'Article',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': url,
      },
      publisher,
      sameAs,
      publishingPrinciples,
    },
  };

  return mergeDefined(webPageSchema, schema);
};
