import { useClassName } from '@/styles/JwVideoTeaser';
import { Component, ComponentProps } from '@/types/component';
import PlayIcon from './icons/play-icon.svg';

import { mergeProps } from '@/utils/mergeProps';

export interface VideoTeaserIconProps extends ComponentProps<'svg'> {}

export const VideoTeaserIconComponent: Component<VideoTeaserIconProps> = (props) => {
  const { children, ...baseProps } = props;
  const className = useClassName('media_icon', baseProps, { defaults: { colors: 'primary' } });

  return <>{children || <PlayIcon {...mergeProps({ className }, baseProps)} />}</>;
};
