import { MutableRefObject, useEffect, useRef } from 'react';

export type RefType<U> = MutableRefObject<U & Element>;

export default function useLazyCallback<T extends HTMLElement>(
  callback: (ref: RefType<T>) => void,
  options?: IntersectionObserverInit
) {
  const elementRef = useRef() as RefType<T>;

  useEffect(() => {
    const lazyVideoObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          callback(elementRef);
          lazyVideoObserver.unobserve(elementRef.current);
        }
      });
    }, options);

    if (elementRef.current) {
      lazyVideoObserver.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        lazyVideoObserver.unobserve(elementRef.current);
      }
      ``;
    };
  }, [callback]);

  return elementRef;
}
