import { ClassNameProvider } from '@/styles/JwVideoTeaser';

import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { VideoTeaser, VideoTeaserProps } from './VideoTeaser';
import { VideoTeaserContentProps } from './VideoTeaser.Content';
import { VideoTeaserContentHintProps } from './VideoTeaser.Content.Hint';
import { VideoTeaserContentTagProps } from './VideoTeaser.Content.Tag';
import { VideoTeaserContentTextProps } from './VideoTeaser.Content.Text';
import { VideoTeaserContentTitleProps } from './VideoTeaser.Content.Title';
import { VideoTeaserHeadlineProps } from './VideoTeaser.Headline';
import { VideoTeaserLinkProps } from './VideoTeaser.Link';
import { VideoTeaserMediaProps } from './VideoTeaser.Media';
import { VideoTeaserIconProps } from './VideoTeaser.Media.Icon';
import { VideoTeaserMediaImageProps } from './VideoTeaser.Media.Image';
import { VideoTeaserMediaPlayIconProps } from './VideoTeaser.Media.PlayIcon';
import { VideoTeaserMediaVideoProps } from './VideoTeaser.Media.Video';

export interface StandaloneJwVideoTeaserProps extends StandaloneComponentProps {
  mediaId: string;
  thumbnail: string;
  title: string;
  isStillImage: boolean;
  teaserLink: string;
  tag?: string;
  headline?: ReactNode;
  playIcon?: ReactNode;
  hintText?: string;
  options?: VideoTeaserProps & {
    $link?: VideoTeaserLinkProps['options'];
    $headline?: VideoTeaserHeadlineProps;
    $media?: VideoTeaserMediaProps & {
      $image?: VideoTeaserMediaImageProps;
      $video?: VideoTeaserMediaVideoProps;
    };
    $content?: VideoTeaserContentProps & {
      $text?: VideoTeaserContentTextProps;
      $tag?: VideoTeaserContentTagProps['options'];
      $title?: VideoTeaserContentTitleProps;
      $hint?: VideoTeaserContentHintProps;
    };
    $playIcon?: VideoTeaserMediaPlayIconProps;
    $icon?: VideoTeaserIconProps;
  };
}

export const StandaloneJwVideoTeaser: StandaloneComponent<StandaloneJwVideoTeaserProps> = ({
  mediaId,
  thumbnail,
  title,
  hintText,
  tag,
  playIcon,
  isStillImage,
  teaserLink,
  headline,
  options,
  ...props
}) => {
  const { $link, $headline, $content, $media, $playIcon, $icon, ...$base } = options || {};
  const { $image: imageProps, $video: videoProps, ...baseMediaProps } = $media ?? {};
  const { $text, $tag, $title, $hint, ...baseContentProps } = $content ?? {};

  return (
    <ClassNameProvider value={$base} options={{ fallbacks: { size: 'variant' } }}>
      <VideoTeaser {...props}>
        {headline && <VideoTeaser.Headline {...$headline}>{headline}</VideoTeaser.Headline>}
        <VideoTeaser.Content {...baseContentProps}>
          <VideoTeaser.Content.Media {...baseMediaProps}>
            <VideoTeaser.Link
              href={teaserLink}
              mediaId={mediaId}
              {...$link}
              content={
                <>
                  {isStillImage ? (
                    <VideoTeaser.Content.Media.Image alt={title} mediaId={mediaId} {...imageProps} />
                  ) : (
                    <>
                      <VideoTeaser.Content.Media.Video thumbnail={thumbnail} mediaId={mediaId} {...videoProps} />
                      <VideoTeaser.Content.Media.PlayIcon {...$playIcon}>
                        <VideoTeaser.Content.Media.PlayIcon.Icon {...$icon}>
                          {playIcon}
                        </VideoTeaser.Content.Media.PlayIcon.Icon>
                      </VideoTeaser.Content.Media.PlayIcon>
                    </>
                  )}
                </>
              }
            />
          </VideoTeaser.Content.Media>

          <VideoTeaser.Content.Text {...$text}>
            {tag && tag != 'null' && (
              <VideoTeaser.Content.Text.Tag link={{ href: `/video/tag/${tag}` }} value={tag} options={$tag} />
            )}
            <VideoTeaser.Link
              href={teaserLink}
              mediaId={mediaId}
              {...$link}
              content={
                <VideoTeaser.Content.Text.Title as={'h3'} {...$title}>
                  {title}
                </VideoTeaser.Content.Text.Title>
              }
            />

            {hintText && (
              <VideoTeaser.Link
                href={teaserLink}
                mediaId={mediaId}
                content={<VideoTeaser.Content.Text.Hint {...$hint}>{hintText}</VideoTeaser.Content.Text.Hint>}
              />
            )}
          </VideoTeaser.Content.Text>
        </VideoTeaser.Content>
      </VideoTeaser>
    </ClassNameProvider>
  );
};
