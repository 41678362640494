import { Content } from '@/types/content';
import getConfig from 'modules/config';

export const shouldPageBeHiddenFromIndexing = (pageData: Content): boolean => {
  const isEtikettPage = pageData.type === 'front' && pageData.data.publishedUrl.includes('etikett');

  const isHiddenFromLabrador = pageData.meta.seoDisableIndexing;
  const isHiddenEtikettPage =
    isEtikettPage && (pageData.data.counter || '') <= getConfig('seo.tagPagesNoIndexThreshold');

  return isHiddenFromLabrador || isHiddenEtikettPage;
};
