import { Tag, TagProps } from '@/components/Tag';
import { StandaloneComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';

export type VideoTeaserContentTagProps = TagProps;

export const VideoTeaserContentTagComponent: StandaloneComponent<VideoTeaserContentTagProps> = (props) => {
  return (
    <Tag
      {...mergeProps(
        {
          options: {
            variant: 'text',
            size: 'video',
            colors: 'alternative',
          },
        },
        props,
        {
          link: {
            options: {
              className: 'flex',
            },
          },
        },
      )}
    />
  );
};
