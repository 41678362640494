import useCookies from './useCookies';

export const useUserName = (defaultName?: string, defaultLastName?: string) => {
  const [{ alltIdData }] = useCookies();

  const userData = JSON.parse(alltIdData || '{}');

  const firstName = userData.name || defaultName || '';
  const lastName = userData.lastName || defaultLastName || '';

  return { firstName, lastName };
};

export default useUserName;
