import { tw } from '@/utils/tw';
import { default as BaseVideoBaseContent } from 'base/components/VideoBaseContent/theme';

const CoverBox = tw.theme({
  extend: BaseVideoBaseContent.CoverBox,
  slots: {
    base: '!text-body-md gap-2 bg-transparent wings-white md:gap-6 md:py-0',
    toggle: 'md:text-body-md bg-white text-body-sm',
    headline: 'text-headline-lg',
  },
});

const Breadcrumbs = tw.theme({
  extend: BaseVideoBaseContent.Breadcrumbs,
});

const TrendingMenu = tw.theme({
  extend: BaseVideoBaseContent.TrendingMenu,
  base: 'mx-0',
});
const VideoBaseContent = tw.theme({
  extend: BaseVideoBaseContent,
});

export default Object.assign(VideoBaseContent, { CoverBox, TrendingMenu, Breadcrumbs });
