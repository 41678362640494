export const getVideoWebPageSchema = (pageData: Record<string, string>) => {
  const webPageSchema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    headline: pageData.seoTitle,
    seoDescription: pageData.seoDescription,
    url: pageData.canonical,
    datePublished: pageData.publishedAt,
    articleSection: 'video',
    creator: [],
    keywords: pageData.keywords,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': pageData.mainEntityId,
    },
    publisher: {
      '@type': 'Organization',
      name: pageData.brand,
      url: pageData.domain,
      logo: {
        '@type': 'ImageObject',
        url: pageData.ogImageUrl,
      },
    },
  };

  return webPageSchema;
};

export const getVideoPageSchema = (pageData: Record<string, string>) => {
  const videoPageSchema = {
    '@context': 'http://schema.org',
    '@type': 'VideoObject',
    name: pageData.seoTitle,
    description: pageData.seoDescription,
    thumbnailUrl: pageData.ogImageUrl,
    uploadDate: pageData?.uploadDate ? new Date(Number(pageData?.uploadDate) * 1000).toISOString() : '',
    contentUrl: pageData?.contentUrl,
    mentions: [
      {
        '@type': 'Thing',
        mentions: pageData?.mentions,
      },
    ],
    duration: pageData?.duration,
    ...(pageData?.embedUrl ? { embedUrl: pageData?.embedUrl } : {}),
    genre: pageData.genre,
    headline: pageData.seoTitle,
    keywords: pageData.keywords,
    caption: pageData.seoTitle,
    ...(pageData.transcript ? { transcript: pageData.transcript } : {}),
    thumbnail: {
      '@type': 'ImageObject',
      url: pageData.ogImageUrl,
      height: 406,
      width: 720,
    },
    ...(pageData.brand === 'all'
      ? {}
      : {
          publisher: {
            '@type': 'Organization',
            name: pageData.brand,
            url: pageData.domain,
            logo: {
              '@type': 'ImageObject',
              url: pageData.ogImageUrl,
            },
          },
        }),
  };

  return videoPageSchema;
};
