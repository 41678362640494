import { OptionalLink } from '@/components/OptionalLink';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Fragment } from 'react';
import { Breadcrumbs } from './Breadcrumbs';

export type StandaloneBreadcrumbsProps = StandaloneComponentProps<typeof Breadcrumbs> & {
  paths: { label: string; url?: string }[];
};

export const StandaloneBreadcrumbs: StandaloneComponent<StandaloneBreadcrumbsProps> = ({ paths, ...props }) => {
  return (
    <Breadcrumbs {...props}>
      {paths?.map((path, index) => (
        <Fragment key={path.label}>
          {path.label && (
            <>
              {Boolean(index) && <Breadcrumbs.Separator>/</Breadcrumbs.Separator>}
              <Breadcrumbs.Step key={path.label}>
                <OptionalLink href={path.url} content={path.label} />
              </Breadcrumbs.Step>
            </>
          )}
        </Fragment>
      ))}
    </Breadcrumbs>
  );
};
