/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneJwVideoTeaser as ResolvedStandaloneJwVideoTeaser } from 'base/components/standalone/JwVideo/Teaser';

export const JwVideoTeaser: typeof ResolvedStandaloneJwVideoTeaser = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneJwVideoTeaser {...props} />
        </ErrorBoundary>
    );
});

export type JwVideoTeaserProps = PropsFromComponent<typeof JwVideoTeaser>;

/** @deprecated Use slot architecture instead */
export const JwVideoTeaserWith = (extras: DynamicStandaloneExtras): typeof JwVideoTeaser => {
    return function JwVideoTeaser(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'JwVideoTeaser');
        return <ResolvedStandaloneJwVideoTeaser {...mergeProps({ options: { theme } }, props)} />;
    }
}