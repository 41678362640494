import { useClassName } from '@/styles/JwVideoTeaser';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';

export interface VideoTeaserHeadlineProps extends ComponentProps<'div'> {
  hideLine?: boolean;
  as?: Extract<keyof JSX.IntrinsicElements, 'h1' | 'h2' | 'h3' | 'h4' | 'h5'>;
}

export const VideoTeaserHeadlineComponent: Component<VideoTeaserHeadlineProps> = ({
  children,
  hideLine,
  as = 'h2',
  ...props
}) => {
  const lineClassName = useClassName('headline_line', props);
  const className = useClassName('headline', props, { className: !hideLine && lineClassName });
  const CustomSelector = as;

  return (
    <div {...mergeProps({ className }, props)}>
      <CustomSelector>{children}</CustomSelector>
    </div>
  );
};
