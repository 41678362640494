import { useClassName } from '@/styles/JwVideoTeaser';
import { ClassNameProp, ComponentProps } from '@/types/component';

import { Component } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';
import { VideoTeaserContentComponent } from './VideoTeaser.Content';
import { VideoTeaserContentHintComponent } from './VideoTeaser.Content.Hint';
import { VideoTeaserContentTagComponent } from './VideoTeaser.Content.Tag';
import { VideoTeaserContentTextComponent } from './VideoTeaser.Content.Text';
import { VideoTeaserContentTitleComponent } from './VideoTeaser.Content.Title';
import { VideoTeaserHeadlineComponent } from './VideoTeaser.Headline';
import { VideoTeaserLinkComponent } from './VideoTeaser.Link';
import { VideoTeaserMediaComponent } from './VideoTeaser.Media';
import { VideoTeaserIconComponent } from './VideoTeaser.Media.Icon';
import { VideoTeaserMediaImageComponent } from './VideoTeaser.Media.Image';
import { VideoTeaserMediaPlayIconComponent } from './VideoTeaser.Media.PlayIcon';
import { VideoTeaserMediaVideoComponent } from './VideoTeaser.Media.Video';

export interface VideoTeaserProps extends ComponentProps<'article'> {
  variant?: ClassNameProp<'vertical' | 'horizontal'>;
  colors?: ClassNameProp<'default' | 'primary' | 'secondary'>;
}

const VideoTeaserComponent: Component<VideoTeaserProps> = ({ children, ...props }) => {
  const className = useClassName('', props);

  return <article {...mergeProps({ className }, props)}>{children}</article>;
};

export const VideoTeaser = Object.assign(VideoTeaserComponent, {
  Headline: VideoTeaserHeadlineComponent,
  Link: VideoTeaserLinkComponent,
  Content: Object.assign(VideoTeaserContentComponent, {
    Media: Object.assign(VideoTeaserMediaComponent, {
      Image: VideoTeaserMediaImageComponent,
      Video: VideoTeaserMediaVideoComponent,
      PlayIcon: Object.assign(VideoTeaserMediaPlayIconComponent, {
        Icon: VideoTeaserIconComponent,
      }),
    }),
    Text: Object.assign(VideoTeaserContentTextComponent, {
      Title: VideoTeaserContentTitleComponent,
      Tag: VideoTeaserContentTagComponent,
      Hint: VideoTeaserContentHintComponent,
    }),
  }),
});
