import { useAppState } from '@/hooks/useAppState';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { withTrackingAttributes } from '@/utils/withTrackingAttributes';
import Tracking from 'base/components/TrackingComponent';
import type { ReactNode } from 'react';
import { TrendingMenu } from './TrendingMenu';

export interface StandaloneTrendingMenuProps extends StandaloneComponentProps<typeof TrendingMenu> {
  headline?: ReactNode;
  tags?: {
    content?: ReactNode;
    prefix?: ReactNode;
    url?: string;
  }[];
}

export const StandaloneTrendingMenu: StandaloneComponent<StandaloneTrendingMenuProps> = ({
  headline,
  tags,
  ...props
}) => {
  const [{ gamesUrls }] = useAppState();

  if (!tags?.length) {
    return null;
  }

  return (
    <Tracking>
      <TrendingMenu {...props}>
        {headline && <TrendingMenu.Headline>{headline}</TrendingMenu.Headline>}
        {tags?.map(({ content, url, prefix }, index) => {
          const isGamesUrl = gamesUrls?.includes(url ?? '');
          return (
            <TrendingMenu.Tag
              key={index}
              value={
                <>
                  {prefix && <TrendingMenu.Prefix>{prefix}</TrendingMenu.Prefix>} {content}
                </>
              }
              link={{
                href: url || '',
                options: { className: 'cts-tracking-object' },
                ...withTrackingAttributes({
                  category: 'menu',
                  name: prefix ? `${prefix} ${content}` : (content as string),
                  url: url,
                  extraInfo: [{ type: 'trending_menu', ...(isGamesUrl ? { campaign: 'keesing_games' } : {}) }],
                }),
              }}
            />
          );
        })}
      </TrendingMenu>
    </Tracking>
  );
};
